<template>
  <div class="job-board">
    <div class="job-board-detail-component">
      <div class="container">
        <div class="row logo-summary">
          <div
            class="col-lg-4 col-sm-12 col-md-12 d-flex justify-content-center align-items-center p-0 leftbox"
          >
            <div class="d-flex flex-column align-items-center">
              <div class="text-center" style="margin-top: 16px">
                <img
                  :src="fileSource.logo"
                  class="rounded-circle ratio-1"
                  style="width: 55% !important"
                  @error="
                    $event.target.src = '/img/hospital.png';
                    $event.target.style = 'width:40%';
                  "
                  alt
                />
              </div>
              <div class="my-3">
                <h6 class="text-primary text-center">{{ organisation }}</h6>
                <div class="location text-primary">
                  <p>
                    <em class="fas fa fa-map-marker-alt"></em>&nbsp;
                    <span style="color: #00000099">{{ location }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-sm-12 col-md-12">
            <div class="title">
              <p class="text-left">{{ title }}</p>
            </div>
            <div class="d-flex">
              <div
                class="detail-group"
                :style="!isAdmins ? 'flex: 0 0 31.33%;' : ''"
              >
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Candidate Type</div>
                  <div class="value">{{ candidateType }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Candidate Level</div>
                  <div class="value">{{ candidateLevel }}</div>
                </div>
              </div>

              <div
                class="detail-group text-left"
                :style="!isAdmins ? 'flex: 0 0 31.33%;' : ''"
              >
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Job Reference</div>
                  <div class="value">{{ jobReference }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Contract</div>
                  <div class="value">{{ contract }}</div>
                </div>
              </div>

              <div
                class="detail-group"
                :style="!isAdmins ? 'flex: 0 0 31.33%;' : ''"
              >
                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Date Posted</div>
                  <div class="value">{{ createdDate }}</div>
                </div>

                <div class="detail-group-item">
                  <div class="label lblspan text-primary">Expires on</div>
                  <div class="value">{{ expiryDate }}</div>
                </div>
              </div>
              <div class="detail-action" v-if="!isAdmins">
                <div class="detail-action-item">
                  <span
                    class="f-1_5 action position-relative multi-select-icon-div"
                    @click="favouriteJob(favouriteID)"
                  >
                    <em
                      v-show="favouriteID"
                      :class="`${
                        favouriteID !== null
                          ? 'text-primary fas fa-heart'
                          : 'far fa-heart'
                      }`"
                      v-c-tooltip="{
                        content: `Click to Remove favourite Job`,
                        placement: 'left',
                      }"
                    ></em>
                    <em
                      v-show="!favouriteID"
                      :class="`${
                        favouriteID !== null
                          ? 'text-primary fas fa-heart'
                          : 'far fa-heart'
                      }`"
                      v-c-tooltip="{
                        content: `Click to Add favourite Job`,
                        placement: 'left',
                      }"
                    ></em>
                  </span>
                </div>
                <div class="detail-action-item text-left">
                  <social-sharing
                    class="share"
                    v-if="true"
                    :url="url"
                    :title="shareTitle"
                    :description="sharing.description"
                    :quote="sharing.quote"
                    :hashtags="sharing.hashtags"
                    :twitter-user="sharing.twitterUser"
                    inline-template
                    v-c-tooltip="{
                      content: `Click to Share`,
                      placement: 'left',
                    }"
                  >
                    <CDropdown
                      class="nav-dropdown px-4"
                      add-menu-classes="pt-0"
                    >
                      <template #toggler-content>
                        <div class="d-flex align-items-center">
                          <CIcon name="cil-share-alt" class="color-primary" />
                        </div>
                      </template>
                      <network network="email">
                        <CDropdownItem>
                          <em class="fa fa-envelope mr-1"></em> Email
                        </CDropdownItem>
                      </network>
                      <div>
                        <network network="facebook">
                          <CDropdownItem>
                            <em class="fab fa-facebook-square mr-1"></em>
                            Facebook
                          </CDropdownItem>
                        </network>
                      </div>
                    </CDropdown>
                  </social-sharing>
                </div>
              </div>
            </div>

            <div v-if="!isAdmins" class="d-flex flex-row-reverse mr-4 mt-4">
              <div class="detail-group">
                <button
                  v-if="!appliedID"
                  type="button"
                  name="JobApply"
                  class="btn btn-primary mb-3"
                  @click="onApplyJob()"
                >
                  Apply Now
                </button>
                <button
                  type="button"
                  class="btn btn-secondary text-primary mb-3"
                  v-else
                  @click="onWithdrawJob()"
                  :disabled="is_rejected"
                >
                  Withdraw
                </button>
                <CBadge
                  v-if="is_rejected && appliedID"
                  color="danger"
                  class="p-2"
                  >Application Unsuccessful</CBadge
                >
              </div>
            </div>
            <div v-else class="d-flex flex-row-reverse">
              <div class="detail-group">
                <div v-if="isToAllowEdit" class="detail-group-item">
                  <button
                    v-if="isActiveJob || isInActiveJob"
                    type="button"
                    class="btn btn-primary mb-3"
                    style="width: 150px"
                    @click="publishOrUnpublish('unpublish')"
                  >
                    Make Draft
                  </button>
                  <button
                    v-else-if="!isPendingJob"
                    type="button"
                    class="btn btn-primary mb-3"
                    style="width: 150px"
                    @click="publishOrUnpublish('publish')"
                  >
                    Preview & Publish
                  </button>
                </div>
              </div>
              <div class="detail-group">
                <div class="action-group">
                  <CDropdown
                    placement="right-start"
                    v-if="isToAllowEdit && isDraftOrPending"
                  >
                    <template #toggler>
                      <button type="button" class="btn btn-primary">
                        Edit
                      </button>
                      <span @click="showNotification()" class="cursor-pointer">
                        <CIcon name="cil-info" class="hinticon-multi-select" />
                      </span>
                    </template>
                    <a
                      @click="onEdit()"
                      class="dropdown-item"
                      style="cursor: pointer"
                      >Edit Job
                    </a>
                    <a
                      v-if="jobDescription"
                      @click="editAdvertDescription()"
                      class="dropdown-item"
                      style="cursor: pointer"
                    >
                      Edit Advert
                    </a>
                  </CDropdown>
                  <p v-if="status" class="my-3 status">{{ status }}</p>
                </div>
              </div>
              <div class="detail-group">
                <div
                  v-if="
                    Roles.includes(currentUserRole) &&
                    ![Role.systemAdmin].includes(currentUserRole) &&
                    (isActiveJob || isDraftOrPending) &&
                    isToAllowEdit
                  "
                  class="detail-group"
                >
                  <button
                    type="button"
                    class="btn btn-primary mb-3"
                    style="width: 110px"
                    @click="onInActiveJob()"
                  >
                    Make Inactive
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="text-center">
        <img
          :src="fileSource.image"
          style="width: 95%; height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
          alt="banner"
        />
      </div>
      <div class="pl-3 pr-3 mt-3 job-description-content" v-html="jobDescription"></div>
    </div>
    <CModal
      id="applyjobModal"
      :title="modalTitle"
      color="primary"
      centered:false
      :show.sync="successModal"
      >{{ this.modalMessage }}
      <template #footer>
        <span>
          <CButton
            class="ml-2"
            variant="outline"
            color="primary"
            @click="applyModalCallBack(false)"
            >Cancel</CButton
          >
          <CButton
            class="ml-2"
            color="primary"
            @click="applyModalCallBack(true)"
            >Confirm</CButton
          >
        </span>
      </template>
    </CModal>

    <CModal
      id="DraftJob"
      title="Draft Job"
      centered:true
      color="primary"
      :show.sync="draftModal"
    >
      <p>{{ draftModalMessage }}</p>
      <template #footer-wrapper>
        <div class="d-flex justify-content-end px-4">
          <button @click="onCancelDraft" class="btn btn-secondary mb-3 mr-3">
            Cancel
          </button>
          <button @click="onDraftSave" class="btn btn-primary mb-3">
            Confirm
          </button>
        </div>
      </template>
    </CModal>

    <CModal id="Preview Job" centered:true :show.sync="publishModal" size="lg">
      <template #header-wrapper>
        <header class="modal-header" style="background: #085d66e6; color: #fff">
          <h5
            class="modal-title px-3"
            style="font-size: 26px; font-weight: 300"
          >
            Preview Job
          </h5>
          <button
            @click="onCancelPublish"
            type="button"
            aria-label="Close"
            class="close"
          >
            ×
          </button>
        </header>
      </template>
      <template #body-wrapper>
        <JobPreview :jobDetails="getSelectedJobBoardAdvert"></JobPreview>
      </template>
      <template #footer-wrapper>
        <div class="border-top d-flex justify-content-between py-3 flex-wrap">
          <div>
            <button
              name="cancel"
              class="btn rounded-0 px-5 btn-lg m-1 mt-3 float-left btn-outline-primary btn-lg mr-2"
              @click="onCancelPublish"
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              name="publish_page"
              class="btn rounded-0 px-5 btn-primary btn-lg m-1 mt-3"
              @click="onPublish()"
            >
              Publish Job
            </button>
          </div>
        </div>
      </template>
    </CModal>
    <CModal
      id="in-active-job-modal"
      title="Inactive Job"
      color="primary"
      centered:false
      @update:show="onUpdateJobStatus"
      :show.sync="updateModal"
    >
      <p>Are you sure you want to deactivate this job?</p>
    </CModal>
    <CModal
      id="notification-modal"
      title="NOTIFICATION"
      color="primary"
      @update:show="notificationModal.show = false"
      :show.sync="notificationModal.show"
    >
      <p v-html="notificationModal.content"></p>
      <template #footer>
        <span>
          <CButton
            class="ml-2"
            variant="outline"
            color="primary"
            @click="notificationModal.show = false"
            >Cancel</CButton
          >
        </span>
      </template>
    </CModal>
    <EditAdvertAloneModal
      v-if="editAdvertAlone.isShowPopup"
      :isShowPopup="editAdvertAlone.isShowPopup"
      :jobData="editAdvertAlone.jobData"
      :modalCallBack="editAdvertAloneCallBack"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import jobDetail from "@/components/JobBoardDetail/jobDetail.vue";
import { Role, getScope } from "@/helpers/helper";
import moment from "moment";
import JobPreview from "@/components/JobDetailPreview/JobDetailPreviewUpdated.vue";
import Modal from "@/components/reusable/Modal";
import EditAdvertAloneModal from "./EditAdvertAloneModal.vue";

export default {
  extends: jobDetail,
  components: {
    JobPreview,
    Modal,
    EditAdvertAloneModal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      Roles: Object.values(Role),
      draftModal: false,
      draftModalMessage: "",
      publishModal: false,
      sharing: {
        url: null,
        title: null,
        description:
          "Hi, I've sent you a link to a job that might be of interest to you. Please click the link above.",
        quote:
          "Hi, I've sent you a link to a job that might be of interest to you. Please click the link below.",
        hashtags: "Talentfind",
        twitterUser: "Talentfind",
      },
      updateJobId: null,
      updateModal: false,
      onChangeJobStatus: "",
      editAdvertAlone: {
        jobData: {},
        isShowPopup: false,
      },
      notificationModal: {
        show: false,
        content: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getOrgID","getOrgIDFromAccessToken", "getUser"]),
    createdDate() {
      let createdDate = this.getSelectedJobBoard?.published_on  ;
      return createdDate ? moment(createdDate).format("DD-MM-YYYY") : 'NA';
    },
    expiryDate() {
      let expiryDate = this.getSelectedJobBoard?.expiry_review_date ;
      return expiryDate ?  moment(expiryDate).format("DD-MM-YYYY"): "NA";
    },
    fileSource() {
      let job = this.getSelectedJobBoard;
      return {
        logo: job?.organisation?.logo_url
          ? window.location.origin + job?.organisation?.logo_url
          : "",
        image: job?.organisation?.default_job_banner_url
          ? window.location.origin + job?.organisation?.default_job_banner_url
          : "",
      };
    },
    isToAllowEdit() {
      if (
        this.Roles.includes(this.currentUserRole) &&
        ![this.Role.systemAdmin].includes(this.currentUserRole)
      ) {
        if (this.getSelectedJobBoard?.organisation_uid == this.getOrgIDFromAccessToken)
          return true;
      }
      return false;
    },
    url() {
      return `https://${window.location.host}/public/job-board/${this.getSelectedJobBoard?.customer_uid}?job_id=${this.getSelectedJobBoard?.job_id}`;
    },
    shareTitle() {
      return `Job Recommendation From: ${this.getUser.first_name?.replace(
        /^./,
        this.getUser.first_name[0].toUpperCase()
      )}`;
    },
  },
  methods: {
    ...mapActions(["modifyPublishJob", "onChangeActiveJobsStatus"]),
    onDraftSave() {
      this.modifyPublishJob({
        organisation_uid: this.getSelectedJobBoard?.organisation_uid,
        job_id: this.getSelectedJobBoard?.job_id,
        published: false,
        expiry_review_date: moment().add(30, "days").format("YYYY-MM-DD"),
      }).then(() => {
        this.onCancelDraft();
        if (this.$router.currentRoute.params.jobStatus == "inactive") {
          this.$store.commit(
            "UPDATE_JOB_BOARD_BY_REMOVING_JOB",
            this.getSelectedJobBoard?.job_id
          );
        }
      });
    },
    onCancelDraft() {
      this.draftModal = false;
    },
    onPublish() {
      this.modifyPublishJob({
        organisation_uid: this.getSelectedJobBoard?.organisation_uid,
        job_id: this.getSelectedJobBoard?.job_id,
        published: true,
      }).then(() => {
        this.onCancelPublish();
        if (this.$router.currentRoute.params.jobStatus == "inactive") {
          this.$store.commit(
            "UPDATE_JOB_BOARD_BY_REMOVING_JOB",
            this.getSelectedJobBoard?.job_id
          );
        }
      });
    },
    onCancelPublish() {
      this.publishModal = false;
    },
    onEdit() {
      this.$router.push({
        path: `/edit-job/${this.getSelectedJobBoard?.job_uuid}?step=0`,
      });
    },
    onInActiveJob() {
      this.updateJobId = this.getSelectedJobBoard?.job_uuid;
      this.onChangeJobStatus = "inactive";
      this.updateModal = true;
    },
    onUpdateJobStatus(e, value) {
      if (value.target.textContent == " OK ") {
        if (this.updateJobId && this.updateModal) {
          this.onChangeActiveJobsStatus({
            job_uuid: this.updateJobId,
            status: this.onChangeJobStatus,
          }).then(() => {
            let route =
              this.onChangeJobStatus == "active" ? "inactive" : "active";
            this.$router.push(`/jobs-list/status/${route}`);
          });
          this.onChangeJobStatus = "";
          this.updateJobId = null;
        }
      }
      this.updateModal = false;
    },
    publishOrUnpublish(action) {
      if (action === "unpublish") {
        this.draftModalMessage =
          "Do you want to change this job to a draft job?";
        if (this.isInActiveJob)
          this.draftModalMessage =
            "Are you sure you want to reactivate this job?";
        this.draftModal = true;
      } else if (action === "publish") {
        this.publishModal = true;
      }
    },
    goToAdditionalDetails() {
      this.$router.push({
        path: `/job-list-preview/${this.getSelectedJobBoard?.job_uuid}`,
      });
    },
    editAdvertDescription() {
      this.editAdvertAlone.jobData = {
        job_id: this.getSelectedJobBoard?.job_id,
        advert_description: this.jobDescription,
        expiry_review_date: new Date(moment().add(30, "days")),
      };
      this.editAdvertAlone.isShowPopup = true;
    },
    editAdvertAloneCallBack(action, value) {
      if (action) {
        this.modifyPublishJob({
          organisation_uid: this.getSelectedJobBoard?.organisation_uid,
          job_id: this.getSelectedJobBoard?.job_id,
          expiry_review_date: moment(value?.expiry_review_date).format(
            "YYYY-MM-DD"
          ),
          advert_description: value?.advert_description,
        }).then(() => {
          this.editAdvertAlone.isShowPopup = false;
        });
      } else {
        this.editAdvertAlone.isShowPopup = false;
      }
    },
    showNotification() {
      this.notificationModal.content =
        "<li>Edit job – this will take you through the original fields – we suggest you use ‘Edit Advert'</li> <li>Edit advert – enables you to correct the text in the advert description below</li>";
      this.notificationModal.show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300;
}
.img-wrapper {
  overflow: hidden;
  background: url(../../../public/img/job-image.jpg) no-repeat 50% 50%;
  background-size: cover;
}
.f-1_5 {
  font-size: 1.5rem;
}
.btn:disabled {
  cursor: not-allowed;
}
</style>
