<template>
  <div>
    <LoadingOverlay v-if="isFetchingGenericFilter" />
    <div class="text-right mt-3" v-if="!isAdmins">
      <router-link to="/home">
        <CButton
          class="spacingHomeBtn spacingHomeBtnRes"
          color="primary"
          shape="pill"
          >Back to Home</CButton
        >
      </router-link>
    </div>
    <GenericFilter
      :basicFilterlength="4"
      :filterSchema="currentSchema"
      :selectedFilters="selectedFilters"
      :filterDependencyObj="filterDependencyObj"
      :isFetchingFilter="isFetchingGenericFilter"
      :callback="updateRouterURL"
      :searchCallback="searchCallback"
      :filterAction="filterAction"
    />
    <div class="job-board">
      <div class="d-flex flex-row">
        <div
          class="d-flex flex-row w-100"
          v-if="
            !isFetchingGenericFilter &&
              !isJobBoardFetching &&
              !getJobBoard.length
          "
        >
          <div class="d-flex flex-column" style="margin-right: 20%">
            <h5 class="count">
              Total Jobs: {{ getJobCount }}
            </h5>
            <h5 class="count">Search Results: {{ getFilterCount }}</h5>
          </div>

          <div class="d-flex flex-column mt-5" v-if="this.$router.currentRoute.params.jobStatus == 'inactive'">
            <h4>Currently there are no Inactive Jobs</h4>
          </div>
          <div class="d-flex flex-column mt-5" v-else>
            <h4>
              No jobs matched the selected criteria
              <span v-if="AddJobRoles.includes(currentUserRole)"
                >, Please add a Job</span
              >
            </h4>
            <CButton
              v-if="AddJobRoles.includes(currentUserRole)"
              @click="navigateToAddJob()"
              class="btn-primary"
              title="Create Job"
              >Create Job</CButton
            >
          </div>
        </div>

        <div
          id="job-board-body"
          :class="{
            'job-board-list col-lg-4': !(
              !isFetchingGenericFilter &&
              !isJobBoardFetching &&
              !getJobBoard.length
            ),
          }"
        >
          <div
            class="d-flex"
            v-if="
              !(
                !isFetchingGenericFilter &&
                !isJobBoardFetching &&
                !getJobBoard.length
              )
            "
          >
            <h6 class="count mr-3">
              Total Jobs: {{ getJobCount }} Search Results: {{ getFilterCount }}
            </h6>
          </div>
          <job-card
            :key="index"
            :index="index"
            :class="
              !isMobile &&
              getSelectedJobBoard &&
              getSelectedJobBoard.job_id == job.job_id
                ? 'highlight'
                : ''
            "
            v-for="(job, index) in getJobBoard"
            :item="job"
            @click.native="jobCardSelectedCallback(job)"
          ></job-card>
          <div v-if="noMoreDataFromJobBoard" class="no-more-data pb-5">
            <p v-if="getJobBoard.length > 0">No More Data!</p>
          </div>
          <div
            v-if="!noMoreDataFromJobBoard"
            class="d-flex justify-content-around align-items-center has-loading-overlay"
            style="height: 100px"
          >
            <LoadingOverlay v-if="isJobBoardFetching" />
          </div>
        </div>
        <div
          v-if="getJobBoard.length > 0 || isJobBoardFetching"
          class="job-board-detail right-main-container pb-4 col-md-8"
        >
          <div
            class="skeleton-card"
            v-if="!(getSelectedJobBoard && getSelectedJobBoard.job_id)"
          ></div>
          <jobDetail
            v-else
            :getSelectedJobBoard="getSelectedJobBoard"
          ></jobDetail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import JobBoard from "../JobBoard.vue";
import jobDetail from "@/components/JobListDetail/jobListDetail.vue";
import advertisement from "@/components/reusable/Advertise.vue";
import { getCustomerId } from "@/helpers/helper";

export default {
  extends: JobBoard,
  components: {
    jobDetail,
    advertisement,
  },
  data() {
    return {
      page: "jobList",
      initJobId: "",
    };
  },
  watch: {
    getSelectedJobBoard() {
      if (!this.$route.fullPath.includes("/jobs-list")) {
        this.initJobId = this.getJobBoard[0].job_id;
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["selectedJobBoard"]),
    jobCardSelectedCallback({ job_id, job_uuid }) {
      if (this.isMobile) {
        this.$router.push({ path: `/job-list-detail/${job_uuid}` });
      } else {
        let queryResult = this.getQueryResult.routeUrl;
        let { fullPath } = this.$router.history.current;
        if (fullPath.search("preferredType") != -1) {
          queryResult = `preferredType${fullPath.substring(
            fullPath.indexOf("preferredType") + 13
          )}`;
        }
        let path = `${this.$route.path}?job_uuid=${job_uuid}&${queryResult}`;
        this.$router.push({ path }).catch(() => {});
        this.selectedJobBoard(job_uuid);
      }
    },
    specificCustomer(job) {
      if (getCustomerId() === 1) return true;
      else return job?.customer_uid == getCustomerId() ? true : false;
    },
  },
  computed: {
    ...mapGetters(["getQueryResult"]),
    currentSchema() {
      if (
        this.getRoleScopes?.length &&
        ["customer_candidate", "system_candidate"].includes(
          this.getRoleScopes[0]
        )
      ) {
        return this.jobBoardSchemaForCandidate;
      } else if(this.getRoleScopes?.length && this.getRoleScopes[0] === 'system_admin'){
        return this.jobBoardSchemaForSysAdmin
      } else {
        return this.jobBoardSchema;
      }
    },
    isAdmins() {
      return (
        this.getRoleScopes.length &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter",
        ].includes(this.getRoleScopes[0])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.job-board-detail::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
@media all and (max-width: 480px) {
  .spacingHomeBtn {
    margin-bottom: 8px !important;
  }
}
@media all and (min-width: 481px) {
  .spacingHomeBtnRes {
    margin-bottom: 8px;
    margin-top: -24px;
  }
}
</style>
