<template>
  <div class="mb-4 pb-2">
    <LoadingOverlay v-if="isFetchingGenericFilter" />
    <GenericFilter
      :basicFilterlength="getFilterSchema.basicFilterLength"
      :filterSchema="getFilterSchema.schema"
      :selectedFilters="selectedFilters"
      :filterDependencyObj="filterDependencyObj"
      :isFetchingFilter="isFetchingGenericFilter"
      :callback="updateRouterURL"
      :searchCallback="searchCallback"
      :filterAction="filterAction"
    />
    <div class="job-board">
      <div v-if="isMobile && jobAdvertisement == true && !isLoading">
        <advertisement :allign="'horizontal'"></advertisement>
      </div>

      <div class="d-flex flex-row">
        <div
          class="d-flex flex-row w-100"
          v-if="
            !isFetchingGenericFilter &&
            !isJobBoardFetching &&
            !getJobBoard.length
          "
        >
          <div class="d-flex flex-column" style="margin-right: 20%">
            <h5 v-if="getJobCount" class="count">
              Total Jobs: {{ getJobCount }}
            </h5>
            <h5 v-if="currentUserRole" class="count">
              Search Results: {{ getFilterCount }}
            </h5>
          </div>

          <div class="d-flex flex-column mt-5">
            <h4 v-if="currentUserRole">
              No jobs matched the selected criteria
              <span v-if="AddJobRoles.includes(currentUserRole)"
                >, Please create a Job</span
              >
            </h4>
            <h4 v-else class="text-center">
              Regional Job Opportunities will be Published Very Shortly.
              <br />Please Check Back Regularly.
            </h4>
            <CButton
              v-if="AddJobRoles.includes(currentUserRole)"
              @click="navigateToAddJob()"
              class="btn-primary"
              title="Create Job"
              >Create Job</CButton
            >
          </div>
        </div>

        <div
          id="job-board-body"
          class="job-board-list col-md-4"
          v-if="
            !(
              !isFetchingGenericFilter &&
              !isJobBoardFetching &&
              !getJobBoard.length
            )
          "
        >
          <div class="d-flex">
            <h6 v-if="getJobCount" class="count">
              Total Jobs: {{ getJobCount }}<span class="pl-3"></span> Search
              Results: {{ getFilterCount }}
            </h6>
            <div
              v-if="page === 'publicJobBoard'"
              class="d-flex mr-0 ml-auto"
              v-c-tooltip="{
                content: 'Display Jobs in Ascending Order in Asc/Desc',
                placement: 'left',
                appendToBody: true,
              }"
              @click="changeOrderBy"
            >
              <span
                class="p-0 btn"
                :class="isOrderByDesc ? 'text-primary' : 'text-muted'"
              >
                <i class="fa-solid fa-arrow-down"></i>
              </span>
              <span
                class="p-0 mx-1 btn"
                :class="isOrderByDesc ? 'text-muted' : 'text-primary'"
              >
                <i class="fa-solid fa-arrow-up"></i>
              </span>
            </div>
          </div>
          <job-card
            :key="index"
            :index="index"
            v-for="(job, index) in getJobBoard"
            :item="job"
            :class="
              !isMobile &&
              getSelectedJobBoard &&
              getSelectedJobBoard.job_id == job.job_id
                ? 'highlight'
                : 'disabled'
            "
            @click.native="jobCardSelectedCallback(job)"
          ></job-card>
          <div v-if="noMoreDataFromJobBoard" class="no-more-data">
            <p v-if="getJobBoard.length > 0">No More Data!</p>
          </div>
          <div
            v-if="!noMoreDataFromJobBoard"
            class="
              d-flex
              justify-content-around
              align-items-center
              has-loading-overlay
            "
            style="height: 200px"
          >
            <LoadingOverlay v-if="isJobBoardFetching" />
          </div>
        </div>
        <div
          class="job-board-detail"
          :class="jobAdvertisement == false ? 'col-md-8' : ' col-md-6'"
          v-if="getJobBoard.length > 0 || isJobBoardFetching"
        >
          <CModal
            id="applyjobModal"
            title="Apply Job"
            color="primary"
            centered:false
            @update:show="onShow"
            :show.sync="successModal"
            >{{ this.modalMessage }}</CModal
          >
          <job-detail
            v-if="checkIsEmptyObject(getSelectedJobBoard)"
            :getSelectedJobBoard="getSelectedJobBoard"
          >
          </job-detail>
        </div>
        <div
          v-if="isMobile != true && jobAdvertisement == true && !isLoading"
          class="col-lg-2 mr-0 ml-auto"
          style="padding-right: 0px"
        >
          <advertisement :allign="'verticle'"></advertisement>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jobCard from "../components/jobCard.vue";
import jobDetail from "../components/JobBoardDetail/jobDetail.vue";
import { mapGetters, mapActions } from "vuex";
import TextInput from "@/components/reusable/Fields/TextInput";
import router from "../router/index";
import { LOGIN_URL, getScope, Role } from "@/helpers/helper";
import GenericFilter from "@/components/reusable/GenericFilterAndSearch/index";
import advertisement from "@/components/reusable/Advertise.vue";
import { isEmptyObjectCheck, isObject } from "@/helpers/helper";

export default {
  components: {
    "job-card": jobCard,
    "job-detail": jobDetail,
    TextInput,
    GenericFilter,
    advertisement,
  },
  data() {
    return {
      searchTerm: "",
      isMobile: false,
      successModal: false,
      modalMessage: "",
      page: "",
      currentUserRole: getScope(),
      Role,
      urlFullPath: "",
      jobId: "",
      jobAdvertisement: false,
      AddJobRoles: [
        Role.systemAdmin,
        Role.customerAdmin,
        Role.customerRecruiter,
        // Role.customerCandidate,
        Role.systemCandidate,
        Role.systemRecruiter,
      ],
      isLoading: false,
      isOrderByDesc: true,
    };
  },
  mounted() {
    this.isLoading = true;
    if (this.$route.params.id) {
      this.getCustomerVariant(this.$route.params.id).then((data) => {
        if (data) {
          this.jobAdvertisement =
            data?.filter((val) => val.key == "JOB_ADVERTISEMENT")[0].value ==
            "TRUE"
              ? true
              : false;
        }
      });
    }
    this.urlFullPath = this.$route.fullPath;
    this.initAllFilters().then(async () => {
      // await this.clearAllSelectedFilters();
      let initialEmptySelectedCheck =
        await this.updateSelectedFilterFromFilterCode(
          this.$router.currentRoute.query
        );
      let queryUrl = this.getQueryResult.queryUrl;
      if (this.currentUserRole) {
        if (
          ["customer_candidate", "system_candidate"].includes(
            this.currentUserRole
          )
        ) {
          if (
            !(
              isObject(this.candidateProfile) &&
              !isEmptyObjectCheck(this.candidateProfile)
            )
          )
            await this.getUserMe({ customer: this.getCustomerId });
          await this.candidateJobCount({ query: "" });
        } else if (["system_admin"].includes(this.currentUserRole)) {
          await this.jobCount({ query: "" });
        } else await this.facilityJobCount({ query: "" });
      } else await this.jobCount({ query: "" });
      //Todo working on the two api calls issue
      this.jobBoardAction({
        page: this.page,
        query: this.getQueryResult.queryUrl,
        orderBy: !this.isOrderByDesc,
      }).then((res) => {
        this.isLoading = false;

        let isMobile = window.matchMedia(
          "only screen and (max-width: 760px)"
        ).matches;
        this.isMobile = isMobile;
        this.createScrollListener();
      });
    });

    this.$store.commit("RESET_JOB_LIST_BACK_URL");
  },
  beforeDestroy() {
    this.removeScrollListener();
  },

  created() {
    let schema = "jobBoardSchema";
    if (this.getRoleScopes.length) {
      if (
        ["system_admin", "system_recruiter"].includes(this.getRoleScopes[0])
      ) {
        this.page = "publicJobBoard";
      } else if (
        ["customer_candidate", "system_candidate"].includes(
          this.getRoleScopes[0]
        )
      ) {
        this.page = "publicJobBoard";
        schema = "jobBoardSchemaForCandidate";
      } else {
        this.page = "jobBoard";
      }
    } else {
      this.page = "publicJobBoard";
      schema = "jobBoardSchemaForCandidate";
    }

    if (this.getRoleScopes[0] === "system_admin") {
      schema = "jobBoardSchemaForSysAdmin";
    }
    this.setCurrentPageSchema({ schema });
  },

  updated() {
    let { job_uuid } = this.$route.query;
    const jobId = job_uuid || 0;
    if (jobId !== this.jobId) {
      this.selectedJobBoard(jobId);
    }
    this.jobId = jobId;
  },
  methods: {
    ...mapActions([
      "jobBoardAction",
      "selectedJobBoard",
      "applyJob",
      "showToast",
      "setCurrentPageSchema",
      "initAllFilters",
      "updateSelectedFilter",
      "clearSearchFilterWithUpdateSearch",
      "updateSelectedFilterFromFilterCode",
      "clearAllSelectedFilters",
      "jobCount",
      "facilityJobCount",
      "getUserMe",
      "candidateJobCount",
      "getGenericSpecialitySearchBasedOnId",
      "getGenericSpecialitySearchBasedOnCode",
      "getCustomerVariant",
    ]),
    searchCallback(payload) {
      payload["type"] = "searchTerm";
      this.updateRouterURL(payload);
    },
    jobCardSelectedCallback({ job_id, job_uuid }) {
      if (this.isMobile) {
        if (this.getRoleScopes.length) {
          if (
            this.getRoleScopes[0] === "system_admin" ||
            this.getRoleScopes[0] === "system_recruiter"
          ) {
            this.$router.push({ path: `/public/job-board-detail/${job_uuid}` });
          } else if (
            this.getRoleScopes[0] === "customer_admin" ||
            this.getRoleScopes[0] === "customer_recruiter"
          ) {
            this.$router.push({ path: `/job-board-detail/${job_uuid}` });
          } else if (this.getRoleScopes[0] === "customer_candidate") {
            this.$router.push({ path: `/public/job-board-detail/${job_uuid}` });
          } else {
            this.$router.push({ path: `/job-board-detail/${job_uuid}` });
          }
        } else {
          this.$router.push({ path: `/public/job-board-detail/${job_uuid}` });
        }
      } else {
        let path = `${this.$route.path}?job_uuid=${job_uuid}&${this.getQueryResult.routeUrl}`;
        this.$router
          .push({
            path,
          })
          .catch(() => {});
        this.selectedJobBoard(this.$route.query.job_uuid);
      }
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromJobBoard || this.isJobBoardFetching) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
        e.target.scrollHeight - 1
      ) {
        this.jobBoardAction({
          pagination: true,
          page: this.page,
          query: this.getQueryResult.queryUrl,
          orderBy: !this.isOrderByDesc,
        });
      }
    },
    handleSearchSubmit() {
      this.jobBoardAction(this.searchTerm);
    },
    handleSearchInput(name, value) {
      this.searchTerm = value;
    },
    clearSearch() {
      this.searchTerm = "";
      this.jobBoardAction();
    },
    onApplyJob() {
      this.successModal = true;
      if (this.getRoleScopes.length) {
        this.modalMessage = "You have selected the Job to Apply";
      } else {
        this.modalMessage = "Please Login/Register to apply for the jobs";
      }
    },
    navigateToAddJob() {
      router.push("/add-job?step=0");
    },
    onShow(e, value) {
      if (value.target.textContent === " OK ") {
        if (this.getRoleScopes.length) {
          this.applyJob(this.getSelectedJobBoard);
        } else {
          this.$router.push({ path: LOGIN_URL() });
        }
      }
    },
    updateRouterURL(payload) {
      let appendAction = [];
      if (payload?.name === "specialitySearch")
        appendAction = [
          ...appendAction,
          this.getGenericSpecialitySearchBasedOnCode(payload),
        ];
      else appendAction = [...appendAction, this.updateSelectedFilter(payload)];
      Promise.all(appendAction).then((res) => {
        let path = `${this.$route.path}?${this.getQueryResult.routeUrl}`;
        this.$router.push({ path });
      });
    },
    filterAction(isAsync = false) {
      this.removeScrollListener();
      if (isAsync) {
        const appendAction = this.getGenericSpecialitySearchBasedOnId();
        Promise.all([appendAction]).then(async (res) => {
          await this.updateSelectedFilterFromFilterCode(
            this.$router.currentRoute.query
          );
          this.jobBoardAction({
            page: this.page,
            query: this.getQueryResult.queryUrl,
            orderBy: !this.isOrderByDesc,
          }).then((res) => {
            this.createScrollListener();
          });
        });
      } else {
        this.initAllFilters().then(async () => {
          await this.clearAllSelectedFilters();
          await this.updateSelectedFilterFromFilterCode(
            this.$router.currentRoute.query
          );
          this.jobBoardAction({
            page: this.page,
            query: this.getQueryResult.queryUrl,
            orderBy: !this.isOrderByDesc,
          }).then((res) => {
            this.createScrollListener();
          });
        });
      }
    },
    checkIsEmptyObject(data) {
      return (isObject(data) && !isEmptyObjectCheck(data)) || false;
    },
    createScrollListener() {
      let ele;
      if (!this.isMobile) {
        ele = document.getElementById("job-board-body");
      } else {
        if (this.urlFullPath.includes("/public")) {
          ele = document.getElementById("public-job-board");
        } else {
          ele = document.getElementById("c-body-app");
        }
      }
      if (ele) {
        ele.addEventListener("scroll", this.onBodyScroll);
      }
    },
    removeScrollListener() {
      let ele;
      if (!this.isMobile) {
        ele = document.getElementById("job-board-body");
      } else {
        if (this.urlFullPath.includes("/public")) {
          ele = document.getElementById("public-job-board");
        } else {
          ele = document.getElementById("c-body-app");
        }
      }
      if (ele) {
        ele.removeEventListener("scroll", this.onBodyScroll);
      }
    },
    changeOrderBy() {
      this.isOrderByDesc = !this.isOrderByDesc;
      this.jobBoardAction({
        page: this.page,
        query: this.getQueryResult.queryUrl,
        orderBy: !this.isOrderByDesc,
      });
    },
  },
  computed: {
    customMarginBasedOnPage() {
      if (
        this.getRoleScopes.length &&
        this.$route.path !== "/public/job-board"
      ) {
        return 10;
      }
      return 70;
    },
    ...mapGetters([
      "getJobBoard",
      "getSelectedJobBoard",
      "noMoreDataFromJobBoard",
      "isJobBoardFetching",
      "getJobCount",
      "getFilterCount",
      "getRoleScopes",
      "jobBoardSchema",
      "jobBoardSchemaForCandidate",
      "selectedFilters",
      "filterDependencyObj",
      "isFetchingGenericFilter",
      "getQueryResult",
      "isSelectedFilterUpdated",
      "getCustomerId",
      "candidateProfile",
      "getSelectedCustomerVariant",
      "jobBoardSchemaForSysAdmin",
    ]),
    getFilterSchema() {
      this.variant = this.getSelectedCustomerVariant;
      return this.currentUserRole &&
        !["customer_candidate", "system_candidate"].includes(
          this.currentUserRole
        )
        ? { schema: this.jobBoardSchema, basicFilterLength: 4 }
        : this.currentUserRole === "system_admin"
        ? { schema: this.jobBoardSchemaForSysAdmin, basicFilterLength: 7 }
        : { schema: this.jobBoardSchemaForCandidate, basicFilterLength: 5 };
    },
  },
};
</script>
