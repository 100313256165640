<template>
  <div class="job-board">
    <div class="job-board-detail-component">
      <div class="container">
        <div class="row logo-summary mt-3">
          <div
            class="
              col-lg-4 col-sm-12 col-md-12
              d-flex
              justify-content-center
              align-items-center
              p-0
            "
          >
            <div class="d-flex flex-column align-items-center">
              <div class="text-center" style="margin-top: 16px">
                <img
                  :src="fileSource.logo"
                  class="rounded-circle ratio-1"
                  style="width: 55% !important"
                  @error="
                    $event.target.src = '/img/hospital.png';
                    $event.target.style = 'width:40%';
                  "
                />
              </div>
              <div class="my-3">
                <h6 class="text-primary text-center">{{ organisation }}</h6>
                <div class="location text-primary">
                  <p>
                    <i class="fas fa fa-map-marker-alt"></i>&nbsp;
                    <span style="color: #00000099">{{ location }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-sm-12 col-md-12">
            <div class="title">
              <p class="text-left">{{ title }}</p>
            </div>
            <div class="d-flex">
              <CRow class="profileView">
                <CCol lg="6">
                  <label class="text-gray">Candidate Type</label>
                </CCol>
                <CCol lg="6">
                  <span class="text-black">{{ candidateType }}</span>
                </CCol>

                <CCol lg="6">
                  <label class="text-gray">Candidate Level</label>
                </CCol>
                <CCol lg="6">
                  <span class="text-black">{{ candidateLevel }}</span>
                </CCol>

                <CCol lg="6">
                  <label class="text-gray">Job Reference</label>
                </CCol>
                <CCol lg="6">
                  <span class="text-black">{{ jobReference }}</span>
                </CCol>

                <CCol lg="6">
                  <label class="text-gray">Contract</label>
                </CCol>
                <CCol lg="6">
                  <span class="text-black">{{ contract }}</span>
                </CCol>

                <CCol lg="6">
                  <label class="text-gray">Date Posted</label>
                </CCol>
                <CCol lg="6">
                  <span class="text-black">{{ date_created }}</span>
                </CCol>

                <CCol lg="6">
                  <label class="text-gray">Expires on</label>
                </CCol>
                <CCol lg="6">
                  <span class="text-black">{{ expiryDate }}</span>
                </CCol>
              </CRow>

              <social-sharing
                v-if="false"
                :url="url"
                :title="title"
                :description="jobReference"
                inline-template
              >
                <CDropdown class="nav-dropdown px-4" add-menu-classes="pt-0">
                  <template #toggler-content>
                    <div class="d-flex align-items-center text">
                      <CIcon name="cil-share-alt" class="color-primary" />
                    </div>
                  </template>
                  <CDropdownItem>
                    <network network="email">
                      <i class="fa fa-envelope"></i> Email
                    </network>
                  </CDropdownItem>
                  <div>
                    <CDropdownItem>
                      <network network="facebook">
                        <i class="fab fa-facebook-square"></i> Facebook
                      </network>
                    </CDropdownItem>
                  </div>
                </CDropdown>
              </social-sharing>
            </div>
            <div class="d-flex flex-row-reverse">
              <div class="detail-group" v-if="isOwnJob">
                <button
                  v-if="!getApplicationStatus"
                  type="button"
                  class="btn btn-primary mb-3"
                  @click="$parent.onApplyJob()"
                >
                  Apply Now
                </button>
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  v-else
                  @click="$parent.onWithdrawJob()"
                >
                  Withdraw
                </button>
              </div>
              <div class="detail-group" v-if="!isOwnJob">
                <button
                  type="button"
                  class="btn btn-primary mb-3"
                  @click="redirectToSharedJobBoard"
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <img
          :src="fileSource.image"
          style="width: 90%; max-height: 250px"
          @error="$event.target.src = '/img/job-image.jpg'"
        />
      </div>

      <div class="pr-2 pl-3 mt-3 job-description-content" v-html="jobDescription"></div>
    </div>
    <Modal
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="modalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import { BASE_URL } from "@/service_urls";
import { LOGIN_URL } from "../../helpers/helper";
import Modal from "@/components/reusable/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      successModal: false,
      urlFullPath: "",
      modalMessage: "",
      isApplyingToJob: false,
      modalTitle: "",
      confirmModal: {
        modalColor: "primary",
        modalTitle: "Are you sure?",
        modalContent: "It will take you to the new site!",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
    };
  },
  created() {
    const {
      fullPath,
      params: { id },
    } = this.$route;
    this.urlFullPath = fullPath;
  },
  computed: {
    ...mapGetters([
      "getSelectedJobBoard",
      "getRoleScopes",
      "getApplicationStatus",
      "getSelectedJobBoardAdvert",
    ]),
    jobStatus() {
      return this.getSelectedJobBoard?.job_status?.status || "";
    },
    isAdmins() {
      return (
        this.getRoleScopes.length &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter",
        ].includes(this.getRoleScopes[0])
      );
    },
    candidateType() {
      return this.getSelectedJobBoard?.candidate_type?.candidate_type || "--";
    },
    candidateLevel() {
      return this.getSelectedJobBoard?.level_names?.join(", ") || "--";
    },
    featured() {
      return this.getSelectedJobBoard?.featured ? "Yes" : "No";
    },
    organisation() {
      if (!this.isOwnJob) {
        return this.getSelectedJobBoard?.organisation_name || "";
      }
      return this.getSelectedJobBoard?.organisation?.name || "--";
    },
    jobDescription() {
      return this.getSelectedJobBoard?.advert_description || "";
    },
    location() {
      return this.getSelectedJobBoard?.location?.location || "--";
    },
    jobReference() {
      return `${this.getSelectedJobBoard?.job_id}` || "--";
    },
    title() {
      return this.getSelectedJobBoard?.job_title || "";
    },
    status() {
      return this.getSelectedJobBoard?.status?.status || "";
    },
    appliedOn() {
      let appliedOn = this.getSelectedJobBoard?.created_on || "";
      return moment(appliedOn).format("YYYY-MM-DD");
    },
    contract() {
      if (this.getSelectedJobBoard) {
        const { permanent_work, temporary_work } = this.getSelectedJobBoard;
        if (permanent_work && temporary_work) return "Permanent;Temporary";
        else if (permanent_work) return "Permanent";
        else if (temporary_work) return "Temporary";
        else return "--";
      } else {
        return "--";
      }
    },
    expiryDate() {
      return (
        moment(this.getSelectedJobBoard?.expiry_review_date).format(
          "DD-MM-YYYY"
        ) || "NA"
      );
    },
    date_created() {
      return (
        (this.getSelectedJobBoard && this.getSelectedJobBoard?.published_on &&
           moment(this.getSelectedJobBoard?.published_on).format("DD-MM-YYYY")) ||
        "NA"
      );
    },
    url() {
      return window?.location?.href;
    },
    hospital() {
      return this.getSelectedJobBoard?.hospital || "--";
    },
    days_ago() {
      let created = moment(this.getSelectedJobBoard?.created_on);
      let today = moment();
      return today.diff(created, "days") !== 0
        ? today.diff(created, "days")
        : "New";
    },
    fileSource() {
      let job = this.getSelectedJobBoard;
      return {
        logo: job?.organisation?.logo_url
          ? window.location.origin + job?.organisation?.logo_url
          : "",
        image: job?.organisation?.default_job_banner_url
          ? window.location.origin + job?.organisation?.default_job_banner_url
          : "",
      };
    },
    favouriteID() {
      return this.getSelectedJobBoard?.ids?.favourite_id || null;
    },
    appliedID() {
      return this.getSelectedJobBoard?.ids?.applied_id || null;
    },
    isActiveJob() {
      let active_job_status = [29];
      return active_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      )
        ? true
        : false;
    },
    isPendingJob() {
      let pending_job_status = [28];
      return pending_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      )
        ? true
        : false;
    },
    isDraftOrPending() {
      let draft_or_pending_job_status = [27, 28];
      return draft_or_pending_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      )
        ? true
        : false;
    },
    isInActiveJob() {
      let inActive_job_status = [30];
      return inActive_job_status.includes(
        this.getSelectedJobBoard?.job_status?.status_id
      )
        ? true
        : false;
    },
    is_rejected() {
      return this.getSelectedJobBoard?.is_accepted === false || false;
    },
    isOwnJob() {
      if (this.getSelectedJobBoard?.is_own_job === null) {
        return true;
      }
      return this.getSelectedJobBoard?.is_own_job;
    },
  },
  methods: {
    ...mapActions([
      "applyJob",
      "cancelJob",
      "showToast",
      "withdrawJob",
      "addPreferredJob",
      "deletePreferredJob",
    ]),
    onApplyJob() {
      this.successModal = true;
      if (this.getRoleScopes.length) {
        this.isApplyingToJob = true;
        this.modalTitle = "Apply Job";
        this.modalMessage =
          "Please confirm you would like to apply for this job";
      } else {
        this.modalMessage = "Please Login/Register to apply for the jobs";
      }
    },
    onWithdrawJob() {
      this.successModal = true;
      this.modalTitle = "Withdraw Job";
      this.modalMessage = "Are you sure you want to withdraw from this job?";
    },
    onShow(e, value) {
      if (value.target.textContent === " OK ") {
        if (this.getRoleScopes.length) {
          if (this.isApplyingToJob) {
            this.applyJob(this.getSelectedJobBoard);
            this.isApplyingToJob = false;
          } else {
            this.withdrawJob(this.getSelectedJobBoard);
          }
        } else {
          this.isApplyingToJob = false;
          this.$router.push({ path: LOGIN_URL() });
        }
      }
    },
    numberWithCommas(number) {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    favouriteJob(favourite_id) {
      let { job_id, job_uuid } = this.getSelectedJobBoard;
      const preferred_type = "favourite";
      if (!favourite_id) {
        this.addPreferredJob({ job_id, job_uuid, preferred_type });
      } else {
        this.deletePreferredJob({
          job_id,
          job_uuid,
          preferred_id: favourite_id,
          preferred_type,
        });
      }
    },
    redirectToSharedJobBoard() {
      this.confirmModal.isShowPopup = true;
      // let url = this.getSelectedJobBoard?.public_job_board_link;
      // let regex =
      //   /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
      // if (regex.test(url)) {
      //   window.open(url);
      // }
    },
    modalCallBack(action) {
      let confirmDelete = "Confirm";
      if (action === confirmDelete) {
        let url = this.getSelectedJobBoard?.public_job_board_link;
        let regex =
          /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (regex.test(url)) {
          window.open(url);
        }
      }
      this.confirmModal.isShowPopup = false;
    },
    applyModalCallBack(action) {
      if (action) {
        if (this.getRoleScopes.length) {
          if (this.isApplyingToJob) {
            this.applyJob(this.getSelectedJobBoard);
            this.isApplyingToJob = false;
          } else {
            this.withdrawJob(this.getSelectedJobBoard);
          }
        } else {
          this.isApplyingToJob = false;
          this.$router.push({ path: LOGIN_URL() });
        }
      }
      this.successModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.lblspan {
  font-size: 16px !important;
  font-weight: 300 !important;
}

.profileView {
  margin: 0px;
  padding: 3px;
  font-size: 12px;
  margin-bottom: 5px;

  .text-gray {
    font-size: 11px;
  }
  div {
    border-bottom: solid 1px #eee;
  }
}

.text-gray {
  color: #6f6f6f;
  padding-right: 5px;
}
</style>
