<template>
  <div class="job-board-list-card cursor-pointer pb-0">
    <div class="title job-card-title d-flex justify-content-between">
      <img
        :src="logoUrl"
        alt="logo"
        class="rounded-circle ratio-1 mr-3 mb-1"
        style="width: 50px !important"
        @error="$event.target.src = '/img/hospital.png'"
      />
      <p class="job-card-title mr-auto" style="line-height: 1">
        {{ jobTitle }}
        <span v-if="postedTime == 'NEW'" class="posted-time">
          {{ postedTime }}
        </span>
        <br /><br />
        Job ID: {{ jobId }}

        <span v-if="isOwnJob == false"
          >&nbsp;|&nbsp; <CBadge color="primary">Shared</CBadge></span
        >
      </p>
      <div class="d-flex">
        <div v-if="featured" class="featured">
          <span>Featured</span>
        </div>
        <div v-if="isAdmins" class="ml-1" :class="`status-${jobStatus}`">
          <span>{{ jobStatus }}</span>
        </div>
      </div>
    </div>
    <div class="location">
      <p>
        <CIcon name="cil-location-pin" />&nbsp; <span>{{ comments }}</span
        >&nbsp;
      </p>
      <div class="organisation mb-2">
        <CIcon name="cil-hospital" />&nbsp; <span>{{ organisation }}</span
        >&nbsp;
      </div>
    </div>
    <p class="content text-truncate">{{ jobDescription }}</p>
    <div v-if="isAdmins">
      <CRow class="text-center count-row">
        <CCol class="count-col">
          <p class="match-span">{{ counts.applied }}</p>
          <p class="m-0">Applied</p>
        </CCol>
        <CCol class="count-col">
          <p class="match-span">{{ counts.shortlist }}</p>
          <p class="m-0">Shortlisted</p>
        </CCol>
        <CCol class="count-col">
          <p class="match-span">{{ counts.interview }}</p>
          <p class="m-0">Interview</p>
        </CCol>
        <CCol class="count-col-noborder">
          <p class="match-span">{{ counts.recruit }}</p>
          <p class="m-0">Recruited</p>
        </CCol>
        <!-- <CCol class="count-col">
          <p class="match-span">{{ counts.review }}</p>
          <p class="m-0">Review</p>
        </CCol>
        <CCol class="count-col">
          <p class="match-span">{{ counts.inProcess }}</p>
          <p class="m-0">In Process</p>
        </CCol>
        <CCol class="p-0">
          <p class="match-span" style="color: red">{{ counts.alerts }}</p>
          <p class="m-0">Alerts</p>
        </CCol> -->
      </CRow>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["item", "index"],
  computed: {
    ...mapGetters(["getRoleScopes"]),
    featured() {
      return this.item.featured || "";
    },
    isAdmins() {
      return (
        this.getRoleScopes.length &&
        [
          "system_admin",
          "system_recruiter",
          "customer_admin",
          "customer_recruiter",
        ].includes(this.getRoleScopes[0])
      );
    },
    published() {
      return this.item.published || "";
    },
    jobStatus() {
      return this.item?.job_status?.status == "Active"
        ? "Published"
        : this.item?.job_status?.status;
    },
    jobTitle() {
      return this.item?.job_title || "--";
    },
    comments() {
      return this.item?.location?.location || "--";
    },
    organisation() {
      if (!this.isOwnJob) {
        return this.item?.organisation_name || "";
      }
      return this.item?.organisation?.name || "";
    },
    postedTime() {
      let created = moment(this.item?.created_on);
      let today = moment();
      return today.diff(created, "days") <= 7 ? "NEW" : "";
    },
    jobDescription() {
      let result = null;
      if (this.item?.advert_description) {
        var html = this.item?.advert_description;
        var div = document.createElement("div");
        div.innerHTML = html;
        var text = div.textContent || div.innerText || "";
        result = text.split(" ").slice(0, 11).join(" ");
      }
      return result;
    },
    jobId() {
      return this.item?.job_id;
    },
    counts() {
      return {
        applied: this.item?.counts?.applied_count || 0,
        review: this.item?.counts?.review_count || 0,
        inProcess: this.item?.counts?.in_process_count || 0,
        shortlist: this.item?.counts?.short_list_count || 0,
        interview: this.item?.counts?.interview_count || 0,
        recruit: this.item?.counts?.recruit_count || 0,
        alerts: 0,
      };
    },
    isOwnJob() {
      if (this.item?.is_own_job === null) {
        return true;
      }
      return this.item?.is_own_job;
    },
    logoUrl() {
      return this.item?.organisation?.logo_url
        ? window.location.origin + this.item?.organisation.logo_url
        : "/img/hospital.png";
    },
  },
};
</script>
<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.job-card-title {
  margin-bottom: 4px;
  font-weight: bolder;
}
.posted-time {
  background-color: var(--primary);
  padding: 2px 9px;
  border-radius: 15px 0px 15px 0px;
  color: var(--white);
  font-weight: bold;
  display: inline-flex;
  font-size: 10px;
  margin-left: 5px;
}
.status-Pending {
  background-color: orange;
  border: 1px solid orange;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-Archive {
  background-color: blue;
  border: 1px solid blue;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-Inactive {
  background-color: red;
  border: 1px solid red;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-Draft {
  background-color: $body-bg;
  border: 1px solid $body-bg;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.status-Published {
  background-color: $green;
  border: 1px solid $green;
  padding: 3px 10px;
  border-radius: 6px;
  color: $white;
  min-width: 70px;
  text-align: center;
  max-height: 18px;
}
.featured {
  background-color: #dd3651;
  border: 1px solid #dd3651;
  padding: 3px 10px;
  margin: 0px 3px;
  border-radius: 6px;
  color: $white;
  min-width: 60px;
  text-align: center;
  max-height: 18px;
}
.count-row {
  font-size: 12px;
  border-top: 1px solid #d8d8d8;
  margin-left: -4px;
  margin-right: -4px;
  .match-span {
    color: green;
    font-weight: 500;
    margin: 0px;
  }
  .count-col {
    border-right: 1px solid #d8d8d8;
    padding: 13px;
  }
  .count-col-noborder {
    border-right: none;
    padding: 13px;
  }
}
</style>
