<template>
  <div class="create pb-4">
    <CCardGroup class="has-loading-overlay">
      <CCard style="border:none;" class="p-2">
        <div style="background-color: #f5f5f0">
          <CRow>
            <div class="d-flex header-content ml-3">
              <div class="title-header" style="min-width: 150px">
                <CRow class="justify-content-center">
                  <img
                    :src="fileSource.logo"
                    class="rounded-circle ratio-1"
                    style="width: 60%"
                    @error="
                      $event.target.src = '/img/hospital.png';
                      $event.target.style = 'width:40%';
                    "
                  />
                </CRow>
                <div class="my-3">
                  <h6 class="text-primary text-center">
                    {{ organisation }}
                  </h6>
                  <h6 class="label lblspan text-primary text-center">
                    <i class="fas fa-map-marker-alt"></i>&nbsp;
                    <span style="color: #00000099">{{ location }}</span>
                  </h6>
                </div>
              </div>
              <div class="p-4" style="min-width: 150px">
                <div class="title">
                  <h4 class="text-left text-primary">{{ title }}</h4>
                </div>
                <CRow>
                  <CCol lg="4" class="property">
                    <label class="text-primary">Candidate Type</label>
                    <label class="value">{{ candidateType }}</label>
                  </CCol>
                  <CCol lg="4" class="property">
                    <label class="text-primary">Job Reference</label>
                    <label class="value">{{ jobReference }}</label>
                  </CCol>
                  <CCol lg="4" class="property">
                    <label class="text-primary">Date Posted</label>
                    <label class="value">{{ date_created }}</label>
                  </CCol>
                  <CCol lg="4" class="property">
                    <label class="text-primary">Candidate Level</label>
                    <label class="value">{{ candidateLevel }}</label>
                  </CCol>
                  <CCol lg="4" class="property">
                    <label class="text-primary">Contract</label>
                    <label class="value">{{ contract }}</label>
                  </CCol>
                  <CCol lg="4" class="property">
                    <label class="text-primary">Expires On</label>
                    <label class="value">{{ expiryDate }}</label>
                  </CCol>
                </CRow>
              </div>
            </div>
          </CRow>
        </div> 
        <div>
          <img
            :src="fileSource.image"
            style="width: 100% !important; max-height: 250px"
            @error="$event.target.src = '/img/job-image.jpg'"
          />
        </div>
        <CCardBody class="p-1 overflow-auto job-description-content" v-html="jobDescription"></CCardBody>
      </CCard>
    </CCardGroup>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { BASE_URL } from "@/service_urls";

export default {
  props: ["jobDetails"],
  computed: {
    ...mapGetters([
      "getOrganisationDocumentFile",
    ]),
    title() {
      return this.jobDetails?.job_title || "--";
    },
    organisation() {
      return this.jobDetails?.organisation?.name || "--";
    },
    candidateType() {
      return this.jobDetails?.candidate_type?.candidate_type || "--";
    },
    candidateLevel() {
      return this.jobDetails?.level_names?.join(", ") || "--";
    },
    jobDescription() {
      return this.jobDetails?.advert_description || "";
    },
    location() {
      return this.jobDetails?.location?.location || "--";
    },
    jobReference() {
      return this.jobDetails?.job_id;
    },
    contract() {
      if (this.jobDetails) {
        const { permanent_work, temporary_work } = this.jobDetails;
        if (permanent_work && temporary_work) return "Permanent;Temporary";
        else if (permanent_work) return "Permanent";
        else if (temporary_work) return "Temporary";
      }
      return "--";
    },
    date_created() {
      return (
        (this.jobDetails &&
          moment(this.jobDetails?.created_on).format("DD-MM-YYYY")) ||
        "NA"
      );
    },
    expiryDate() {
      let expiryDate = this.jobDetails?.expiry_review_date || "NA";
      return moment(expiryDate).format("DD-MM-YYYY");
    },
    fileSource() {
      let job = this.jobDetails;
      return {
        logo: job?.organisation?.logo_url
          ? window.location.origin + job?.organisation?.logo_url
          : "",
        image: job?.organisation?.default_job_banner_url
          ? window.location.origin + job?.organisation?.default_job_banner_url
          : "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.property {
  font-size: 13px !important;
  font-weight: bold !important;
}
.value {
  font-size: 12px !important;
  font-weight: 400 !important;
  display: block;
}
.title-header {
  max-width: 25%;
}
.org-logo {
  height: 35%;
  width: 35%;
}
.property {
  min-width: 150px;
}
@media all and (max-width: 992px) {
  .property {
    min-width: 135px;
  }
}
@media all and (max-width: 506px) {
  .title-header {
    max-width: 100%;
  }
  .org-logo {
    height: 25%;
    width: 25%;
  }
  .header-content {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .value {
   border-bottom: solid 1px #EEE;
  }
}
</style>
