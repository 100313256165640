<template>
  <div class="pl-2">
    <CModal
      color="primary"
      :show.sync="isShowModal"
      :closeOnBackdrop="false"
      size="xl"
    >
      <template #header>
        <h5>Edit Advert Description</h5>
        <button aria-label="Close" class="close" @click="modalCallBack(false)">
          x
        </button>
      </template>
      <div>
        <ValidationObserver
          ref="editAdvertDescription"
          v-slot="{ handleSubmit }"
        >
          <form id="editAdvertDescription" @submit.prevent="handleSubmit()">
            <CRow v-if="jobs.expiry_review_date">
              <CCol md="6">
                <CRow class="row mb-3 ml-1">
                  <label class="required col-lg-12 col-md-12"
                    >Expiry Review Date</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <DatePicker
                        name="expiry_review_date"
                        :value="jobs.expiry_review_date"
                        @input="handleDatePickerChange"
                        :showFromTmw="true"
                        :error="errors[0]"
                        format="DD-MM-YYYY"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <rich-text-editor
                name="advert_description"
                :value="jobs.advert_description"
                @input="handleEditor"
              />
            </div>
          </form>
        </ValidationObserver>
      </div>
      <template #footer>
        <div v-for="button in ['Cancel', 'Submit']" :key="button">
          <CButton
            :color="button == 'Cancel' ? 'secondary' : 'primary'"
            @click="onSubmit(button)"
          >
            {{ button }}
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import AddJob from "../../containers/AddJobs/AddJob";
export default {
  name: "editAdvertAloneModal",
  props: ["isShowPopup", "modalCallBack", "jobData"],
  mixins: [AddJob],
  data() {
    return {
      isShow: this.isShowPopup,
      jobs: {},
    };
  },
  watch: {
    isShowPopup(newVal) {
      this.isShow = newVal;
    },
  },
  computed: {
    isShowModal: {
      get() {
        return this.isShow;
      },
      set(isShowPopup) {
        this.isShow = isShowPopup;
      },
    },
  },
  methods: {
    handleEditor(name, value) {
      Vue.set(this.jobs, name, value);
    },
    async onSubmit(action) {
      if (action == "Submit") {
        const isValid = await this.$refs.editAdvertDescription.validate();
        if (!isValid) {
          this.showToast({
            class: "bg-danger text-white",
            message: "please fill mandatory fields!",
          });
          return;
        } else {
          this.modalCallBack(true, this.jobs);
        }
      } else this.modalCallBack(false);
    },
  },
  mounted() {
    this.jobs = this.jobData;
  },
};
</script>
<style lang="scss" scoped>
.modal-footer {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
</style>
