<template>
  <div :id="allign">
    <div class="advertise-container">
      <div class="marquee">
        <div class="marquee--inner">
          <span>
            <div v-for="(n, index) in nTimes" :key="index">
              <div
                class="orb"
                v-for="(jobAdvert, index) in activeJobAdvertisements"
                :key="'ads' + index"
              >
                <a :href="jobAdvert.link | getLink" target="_blank"
                  ><img :src="jobAdvert.base64_image_data"
                /></a>
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";

export default {
  name: "Advertise",
  components: {},
  props: ["allign"],
  data() {
    return {
      currentUserRole: getScope(),
      Roles: Object.values(Role),
    };
  },
  computed: {
    ...mapGetters(["jobAdvertisements"]),
    activeJobAdvertisements() {
      return (
        (this.jobAdvertisements &&
          this.jobAdvertisements.filter(({ is_active }) => is_active)) ||
        []
      );
    },
    nTimes() {
      return !Math.floor(this.jobAdvertisements.length / 7)
        ? 7 - Math.floor(this.jobAdvertisements.length)
        : 1;
    },
  },
  methods: {
    ...mapActions(["fetchJobAdvertisement"]),
  },
  mounted() {
    this.fetchJobAdvertisement();
  },
  filters: {
    getLink(link) {
      if (link)
        return /^(?:f|ht)tps?\:\/\//.test(link) ? link : `http://${link}`;
      return "javascript:void(0)";
    },
  },
};
</script>
<style lang="scss" scoped>
#horizontal {
  .marquee--inner {
    display: block;
    width: 500%;
    margin: 0px 0;
    position: absolute;
    animation: hmarquee 20s linear infinite;
  }

  .advertise-container {
    height: 123px;

    .orb {
      display: inline-block;
      float: left;
      transition: all 0.2s ease-out;

      img {
        margin-right: 5px;
        height: 109px;
        width: auto;
      }
    }
  }
}

#verticle {
  .marquee--inner {
    display: block;
    height: 200%;
    width: 100%;
    margin: 0px 0;
    position: absolute;
    animation: vmarquee 20s linear infinite;
  }

  .advertise-container {
    height: calc(100vh - 50px);
  }

  .orb {
    display: inline-block;
    float: left;
    transition: all 0.2s ease-out;

    img {
      border-bottom: dotted 2px $border-primary-color;
      width: 100%;
      margin-bottom: 5px;
    }
  }
}
.advertise-container {
  background: $white;
  padding: 5px;
  box-shadow: -1px 0px 7px 0px $border-primary-color;

  .orb:hover {
    transform: scale(1.2);
    cursor: pointer;
  }

  .marquee {
    height: 100%;
    width: 100%;
    border: 2px solid #eee;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
  }

  .marquee--inner:hover {
    animation-play-state: paused;
  }
}

@keyframes hmarquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}

@keyframes vmarquee {
  0% {
    top: 0%;
  }
  100% {
    top: -100%;
  }
}
</style>